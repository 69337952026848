.add-button {
  display: flex;
  justify-content: space-between;
}
.MuiSelect-select.Mui-disabled {
  cursor: no-drop;
}
.clr-white {
  li {
    color: var(--white);
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.policy-header {
  //text-decoration: underline;
  cursor: default;
  color: var(--blue-green);
}
.policy-header:hover {
  color: var(--blue-green);
}
.policy-icon {
  margin: -15px;
  font-size: 2.25rem;
}
.policy-icon:hover {
  color: var(--blue-green);
}
.MuiSvgIcon-root.MuiSelect-icon {
  right: -10px !important;
}
// .table-padding{
//   padding: 40px;
// }
.mt-2 {
  margin-top: 2em !important;
}
.toolcase-table .MuiTableCell-body .MuiSvgIcon-root {
  color: var(--blue-green) !important;
}
.template-text {
  //font-style: italic !important;
  font-weight: bold !important;
  color: #008AA6 !important;
  font-size: 14px !important;
  //text-decoration: underline;
  //text-decoration-thickness: 2px;
}
.multi-select .css-48ayfv+div {
  width: 100% !important;
}
.multi-select .css-11unzgr {
  max-height: 300px !important;
}
.toolcase-table .MuiToolbar-regular:first-child {
  min-height: 24px !important;
  max-height: 36px !important;
}

.toolcase-table .MuiToolbar-root div div span:first-child {
  width: 60px !important;
}
.MuiTablePagination-select {
  padding-top: 10px !important;
}
textarea:focus-visible {
  outline: 0 !important;
}
.export-btn {
  top: 20px !important;
}
.MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
  background-color: #005159 !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #008686 !important;
}

.p-0 {
  padding: 0 !important;
}