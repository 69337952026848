.add-button {
  display: flex;
  justify-content: space-between;
}
.MuiSelect-select.Mui-disabled {
  cursor: no-drop !important;
    background: rgb(179 169 169 / 10%) !important;
}
.clr-white {
  li {
    color: var(--white);
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.policy-header {
  //text-decoration: underline;
  cursor: default;
  color: var(--blue-green);
}
.policy-header:hover {
  color: var(--blue-green);
}
.policy-icon {
  margin: -15px;
  font-size: 2.25rem;
}
.policy-icon:hover {
  color: var(--blue-green);
}
.MuiSvgIcon-root.MuiSelect-icon {
  right: -10px !important;
}
// .table-padding{
//   padding: 40px;
// }

.css-1r4vtzz, .css-48ayfv {
    width: 100% !important;
    height: 3.9em !important;
    background: #e8f8f8 !important;
    border-bottom: 1px solid #00B3B3 !important;
    box-shadow: 0 0 0 0 !important;
}
.css-11unzgr {
    max-height: 200px !important;
}
.css-ik6y5r {
    box-shadow: 0 0 0 0 !important;
    background: #e8f8f8 !important;
}
.css-48ayfv + div {
    background: #e8f8f8 !important;
    //width: 100% !important;
    right: 0px !important;
}

.pb-10 {
    padding-bottom: 10em !important;
}
.pt-2 {
    padding-top: 2em !important;
}
.division_field div {
    width: 100% ;
}
.bu_field div {
    width: 100% ;
}
.bs_field div {
    width: 100%;
}
.css-1hwfws3 {
    width: 75% !important;
}
.css-1wy0on6 {
    width: 20% !important;
}
.css-1jllj6i-control{ 
    width: 90% !important;
}
