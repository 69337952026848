.toolcase-table .MuiToolbar-regular:first-child {
    min-height: 24px !important;
    max-height: 36px !important;
}
.toolcase-table .MuiToolbar-regular:last-child {
    min-height: 64px !important;
    max-height: 64px !important;
}

.toolcase-table .MuiToolbar-root div div span:first-child {
    width: 60px !important;
}

.workflow-table .MuiToolbar-root div div span:first-child {
    width: 52px !important;
}

.workflow-table .MuiButtonBase-root {
    top: 10px !important;
}
.workflow-table .MuiTablePagination-selectRoot {
    top: 10px !important;
}

.workflow-table .MuiTypography-caption {
    align-self: flex-end !important;
}