.unauthorized-image {
    height: 250px;
    width: 100%;
}

.my-it-link {
    font-style: italic;
    border-bottom: 1px solid #00cccc;
    color: #00cccc;
}
