.MuiToolbar-root div div span:first-child {
    display: inline-block !important;
    width: 36px !important;
}
// .FormAdjGrid .MuiToolbar-root div div span:first-child {
//     display: inline-block !important;
//     width: 6rem !important;
// }
.issueTable table:first-child tr:last-child {
    background: #F2E9D0 !important;
}

.MuiFormLabel-asterisk {
    color: red;
}
.issueTable table:first-child tr:last-child td {
    font-weight: bold !important;
}