@import "../../assets/colors.css";

.litigation-main {
    margin-top: 90px;
    background: var(--light-sand);
}

.litigation-homepage--banner {
    background: url("../../assets/images/BannerImage.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 60vh;
    width: 100%;
    box-shadow: 0 10px 10px #aaaa96;
    margin-bottom: 30px;
}

.litigation-homepage--card--block {
    display: flex;
    /*flex-wrap: wrap;*/
}

.litigation-homepage--card--block-item {
    height: 250px;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 5px 10px 10px #CCCCCC;
    transition: 0.25s;
    /*margin: 0 30px;*/
    border-top: 2px solid #CCCCCC33;
    border-left: 2px solid #CCCCCC33;
}


.litigation-homepage--card--block-item:hover {
    box-shadow: 1px 1px 10px #aaaa9633;
    transform: scale(1.05);
    border-top: 10px solid transparent;
    border-left: 2px solid #CCCCCC33;
}

/*.litigation-homepage--card--block-item:hover .litigation-homepage--card--block-item-text {
            
        }*/


.litigation-homepage--card--block-item-text {
    text-transform: uppercase;
    color: var(--white);
    background: #002949E6;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 15px;
    font-size: 14px;
    text-align: center;
    transition: 0.75s;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.litigation-homepage--card--block-item--special {
    background: url(../../assets/images/Sales.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.litigation-homepage--card--block-item--cfr {
    background: url(../../assets/images/Reports.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.litigation-homepage--card--block-item--event {
    background: url(../../assets/images/Services.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.litigation-homepage--card--block-item--refund {
    background: url(../../assets/images/charts.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
    .litigation-homepage--card--block-item {
        margin: 0 40px 0 20px;
        height: 250px;
    }

    .litigation-homepage--banner {
        display: none;
    }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
    .litigation-homepage--card--block-item {
        margin: 0px 40px 0 20px;
        height: 250px;
    }

    .litigation-homepage--banner {
        background-size: auto;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .litigation-homepage--card--block-item {
        margin: 0 30px;
    }
}

.title-hidden {
    display: none;
}

.multi-select .css-48ayfv+div {
    width: 100% !important;
}
.align-self {
    align-self: center;
}
.cfr-table.MTablePaginationInner-root span {
    padding-top: -10px !important;
}
.i-frame {
    box-shadow: 1px 2px 5px 3px #ccc;
    border-radius: 8px;
    padding: 1rem 0 0 0;
}
.align-center {
    align-self: center;
}